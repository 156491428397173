
.o-section-content {
    background: #3f1224;
    position: relative;
    padding: 70px 15px 70px 15px;
    margin-top: 50px;

    @include respond(phone){
        position: relative;
        padding: 70px 15px 70px 15px;
}

    // @include respond(tab-port){
    //         position: relative;
    //         padding: 70px 15px 70px 15px;
    //         margin: 15px;
    // }
}

.o-meal-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
}

.m-meal-weeks {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.m-meal-weeks__bbutton--left {
    margin-right: 30px;
}

.a-bbutton--nav {
    background: #272e39;
    width: 70px;
    height: 70px;
    padding: 0;
    justify-content: center;
}

.a-bbutton {
    cursor: pointer;
    // padding: 40px;
    display: flex;
    font-style: italic;
    align-items: center;
    // background: #0b0b0d;
    transition: background .35s;
}

.a-bbutton--nav .a-bbutton__icon {
    padding: 0;
    margin: 0;
    fill: #fff;
}

.m-meal-weeks__select {
    display: flex;
    align-items: center;
}

.m-meal-weeks__icon {
    margin-right: 30px;
    min-width: 57px;
    min-height: 57px;
    fill: #828489;
}

.a-icon {
    width: 25px;
    // height: 25px;
    box-sizing: content-box;
    transition: fill .35s;
    color: $white;
    font-size: 25px;
}

.m-meal-weeks__input {
    width: 280px;
}

.a-input__select {
    position: relative;
}

.a-input__input--big {
    height: 70px;
}

.a-input__input {
    padding: 0 15px;
    height: 60px;
    outline: none;
    background-color: #A16383;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    border: 2px solid #3f1224;
    border-radius: 0!important;
    width: 100%;
    font-family: Roboto Condensed;
    transition: background .35s,border .35s;
}

.m-meal-weeks__bbutton--right {
    margin-left: 30px;
}

// table start
.o-meal__table-wrap {
    overflow-y: auto;
}

.o-meal-table__head {
    display: flex;
    align-items: flex-end;
}

// .o-meal-table__head-item {
//     flex: 1;
// }

.a-meal-week {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 1.875em;
    text-transform: uppercase;
    font-weight: 700;
    min-height: 70px;
    justify-content: center;
    background-color: #A16383;
    color: $white;
}

.a-meal-food {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 700;
    text-transform: uppercase;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #240a15;
    color: $white;
}



.o-meal-table__row {
    position: relative;
    display: flex;
}

.o-meal-day__col {
    position: relative;
    flex: 1;
    height: auto;
    z-index: 1;
}

.o-meal-table__col {
    position: relative;
    // flex: 1;
    height: 100%;
    z-index: 1;
}

.a-meal-day {
    position: relative;
    height: 100%;
    background-color: #240a15;
}

.a-meal-day__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:#250915;
}

.a-meal-day__content {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.a-meal-day__day {
    font-size: 1.25em;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    @include respond(phone){
        font-size: 2em;
    }
}

#a-meal-day__day-small {
    font-size: 1.25em;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
}

.o-meal-table__items {
    position: relative;
    // display: flex;
    z-index: 1;
    // flex: 1
}

.o-meal-table__items>.o-meal-table__col {
    padding: 4px;
    border-right: 1px solid #3f1224;
    border-bottom: 1px solid #3f1224;
}

.m-meal-item {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 5px;
    display: flex;
    flex-direction: column;

    // justify-content: flex-end;
    background: #250915;

    transition: opacity .35s;
}

.m-meal-item__header {
    display: flex;
    flex-direction: column;
}

.m-meal-item__content {
    position: relative;
    transition: .35s;
    z-index: 2;
}

.meal-item__list {
    background-color: transparent;
    color: $white;
}

.m-meal-item__title {
    max-width: 105px;
    color: hsla(0,0%,100%,.5);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    line-height: .95;
    max-height: 3.8em;
    transition: color .35s;
}

.o-meal-table__overlay {
    position: absolute;
    background: #3f1224;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: opacity .35s;
    z-index: 0;
}

// media query
@media screen and (max-width: 375px){
    .m-meal-weeks__icon {
        margin-right: 0px;
        min-width: 36px;
        min-height: 57px;
        fill: #828489;
    }
}
@media screen and (max-width: 425px){
    // .o-section-content {
    //     position: relative;
    //     padding: 70px 15px 70px 15px;
    //     // margin-top: 50px;
    //     // margin: 20px 20px 20px 10px;
    // }

    .m-meal-weeks__input {
        width: 111px; 
    } 
    
    .o-meal-day__col {
        position: relative;
        // flex: 1;
        height: 70px;
        z-index: 1;
    }

    .o-meal-table__col {
        position: relative;
        // flex: 1;
        height: 200px;
        z-index: 1;
    }

    .a-meal-food {
        display: none;
    }

    .a-meal-food-small {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 700;
        text-transform: uppercase;
        min-height: 70px;
        justify-content: center;
        align-items: center;
        background-color: #A16383;
        color: #fff;
        font-size: 2rem;
    }

    .m-meal-item__title {
        font-size: 2rem;
    }

    .meal-item__list {
        background-color: transparent;
        color: #ffffff;
        font-size: 1.5rem;
    }
    
}

@media screen and (min-width: 768px){
    .a-meal-food-small {
        display: none;
    }
}