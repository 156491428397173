.summary-content {
    background: $summary-section;
    position: relative;
    padding: 50px 20px 50px 20px;

//     @include respond(phone){
//         padding: 50px 20px 50px 20px;
// }

    // @include respond(tab-port){
    //     margin: 15px;
    // }
}

.summary_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.summary-title{
        font-size: 3.188em;
        font-weight: 700;
        font-style: italic;
        font-stretch: condensed;
        text-transform: uppercase;
        max-width: 500px;
        line-height: .88;
        color: #fff;        
}

.summary-info {
    display: flex;
    color: #fff;
}

.summary-info-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 30px;
}

.summary-info-icon {
    fill: #fff;
    width: 30px;
    height: 30px;
}

.summary-info-content {
    margin-left: 15px;
    text-align: center;
    font-size: 2.8rem;
    font-weight: bold;
}

.summary-info-subtitle {
    text-transform: capitalize;
    font-size: 1.3rem;
    color: hsla(0,0%,100%,.5);
}

.summary-info-item:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: hsla(0,0%,100%,.5);
}

.summary-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 45px;
}

.summary-item {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
}

// .summary-item:nth-child(odd) {
//     padding-left: 0;
// }

.m-summary {
    position: relative;
    height: 550px;
    background: #252323;
}

.m-summary__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 80px 15px;
    height: 100%;
    align-items: center;
    min-height: 300px;
    justify-content: space-between;
}

.m-summary__title {
    padding: 0 80px;
    max-width: 300px;
    align-self: flex-start;
    font-size: 2.5em;
    text-transform: uppercase;
    font-weight: 700;
    font-style: italic;
    color: hsla(0,0%,100%,.4);
    line-height: .88;
}

.svg-graph-1 {
    position: relative;
    width: 90%;
    height: 250px;
}

.svg-graph-1__info, .svg-graph-1__title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.svg-graph-1__info {
    position: absolute;
    flex-direction: column;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 100%;
}

.svg-graph-1__title {
    margin-top: 50px;
    // color: #fff;
}

.svg-graph-1__subtitle {
    color: hsla(0,0%,100%,.6);
    font-size: 1.6rem;
}

.svg-graph-1 svg {
    width: 100%;
    height: 100%;
}

.svg-graph-1__title-number {
    font-size: 4.375em;
}

.svg-graph-1__title-sup {
    align-self: flex-start;
    font-size: 2.5em;
}

.m-summary__subtitle {
    max-width: 450px;
    font-size: 2rem;
    font-weight: 700;
    font-style: italic;
    line-height: 1.05em;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

// media query
@media screen and (max-width: 375px){
    .summary-info-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 5px 8px !important;
        }
}

@media screen and (max-width: 425px){

    .summary-content {
        background: $summary-section;
        position: relative;
        padding: 50px 20px 50px 20px;
    }

.summary-info-item {
position: relative;
display: flex;
align-items: center;
padding: 10px 8px;
}

.svg-graph-1__info {
position: absolute;
flex-direction: column;
/* bottom: 0; */
/* right: 0; */
/* left: 0; */
margin: 0 auto;
/* height: 100%; */
}

.m-summary__title {
/* padding: 0 80px; */
max-width: 400px;
align-self: center;
font-size: 2.5em;
text-transform: uppercase;
font-weight: 700;
font-style: italic;
color: rgba(255, 255, 255, 0.4);
line-height: .88;
}

.summary-card-img{
    width: 100px;
}

.summary-item {
    width: 100%;
    padding: 0 0px;
    margin-bottom: 20px;
}

.m-summary {
    position: relative;
    height: 300px;
    background: #252323;
}

.m-summary__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
    height: 100%;
    align-items: center;
    min-height: 300px;
    justify-content: space-between;
}
}