@import "./mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  //box-sizing: inherit;
  // font-family: "Helvetica";
  box-sizing: border-box;
}

html {
  font-size: 62.5%; //1rem = 16px  10/16px

  @include respond(too-big) {
    font-size: 150%;
  }

  @include respond(mac) {
    font-size: 150%; //1rem =24px 24/16px
  }

  @include respond(small-mac) {
    font-size: 100%;
  }

  @include respond(hdpi) {
    font-size: 90%;
  }

  @include respond(big-desktop) {
    font-size: 80%;
  }

  @include respond(desktop) {
    font-size: 70%;
  }

  @include respond(tab-land) {
    font-size: 62.2%;
  }

  @include respond(tab-pro) {
    font-size: 65%;
  }

  @include respond(tab-port) {
    font-size: 60%; //1rem=8px 8/16
  }

  @include respond(phone) {
    font-size: 45%; //1rem =5px 5/16
  }

  @include respond(minPhone) {
    font-size: 30%;
  }
}

body {
  font-family: 'Exo', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.7;
  background-color: #59253a;
  box-sizing: border-box;
  // padding:0 2rem;
  max-width: 100%;

  @include respond(phone) {
    padding: 0 0rem;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  height: 100vh;
  background-color: #16213e;

  // background-color: #b4b4b4;
  @include respond(phone) {
    width: 100%;
  }

}

.About {
  background-image: linear-gradient(to right bottom,
      rgba(#16213e, 0.95),
      rgba(#16213e, 0.95)), url("https://images.pexels.com/photos/1120575/pexels-photo-1120575.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  height: 80vh;
  background-color: #16213e;
  padding: 5rem;

  @include respond(phone) {
    height: 120vh;

  }

  &-center {
    text-align: center;
    transform: translateY(45%);

    @include respond(tab-pro) {
      height: 60vh;
    }

    @include respond(phone) {
      transform: translateY(5%);
    }

    p {
      color: #fff;
      font-size: 15px;
    }

  }

}

.features {
  &-center {
    text-align: center;

    // transform: translateY(20%);
    @include respond(phone) {
      transform: translateY(20%);
    }
  }

  height:80vh;
  min-height: 80vh;
  background-color: #16213e;
  padding: 5rem;
  background-image:linear-gradient(to right bottom,
    rgba(#16213e, 0.95),
    rgba(#16213e, 0.95)),
  url("https://images.pexels.com/photos/95916/pexels-photo-95916.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");

  @include respond(tab-pro) {
    height: 60vh;
    min-height: 80vh;
  }

  @include respond(phone) {
    height: 100vh;

  }
}

.Download {
  height: 60vh;
  background-color: #16213e;
  padding: 5rem;
  min-height: 60vh;
}

.comingSoon {
  width: 80rem;
  background-color: #222831;
  height: 30vh;
  transform: translateX(30%);
  border-radius: 40px;

  -webkit-box-shadow: 10px 10px 101px -13px rgba(236, 235, 235, 0.75);
  -moz-box-shadow: 10px 10px 101px -13px rgba(221, 217, 217, 0.75);
  box-shadow: 10px 10px 101px -13px rgba(228, 223, 223, 0.75);

  &-header {
    transform: translateY(70%);
    font-size: 5rem;
    background: -webkit-linear-gradient(#ffffff, #bbbbbb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  @include respond(tab-pro) {
    width: 80rem;
    transform: translateX(5%);
  }

  @include respond(tab-port) {
    width: 70rem;
    transform: translateX(0);
  }

  @include respond(phone) {
    width: 45rem;
    transform: translateX(0);
  }

  // @include respond(minPhone){
  //   width: 20rem ;
  //   transform: translateX(0);
  // }

}

.Header {

  font-size: 5rem;
  background: -webkit-linear-gradient(#af2d2d, #f05454);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5rem;


}

.cover-1 {
  position: relative;
  width: "100%";
  height: 90vh;
  text-align: center;
  background-image: linear-gradient(to right bottom,
      rgba(#16213e, 0.65),
      rgba(#214252, 0.55)),
    url("https://images.unsplash.com/photo-1551984427-6d77a1918093?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1906&q=80");

  background-size: auto;
  background-repeat: no-repeat;

  @include respond(phone) {
    background-size: cover;
    height: 70vh;
  }
}

.cover-2 {
  width: "100%";
  height: 90vh;
  text-align: center;
  background-image: linear-gradient(to right bottom,
      rgba(#16213e, 0.65),
      rgba(#214252, 0.55)),
    url("https://images.unsplash.com/photo-1496412705862-e0088f16f791?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80");
  background-size: auto;
  background-repeat: no-repeat;

  @include respond(phone) {
    background-size: cover;
    height: 70vh;
  }

}

.cover-3 {
  width: "100%";
  height: 90vh;
  text-align: center;
  background-image: linear-gradient(to right bottom,
      rgba(#16213e, 0.65),
      rgba(#214252, 0.55)),
    url("https://images.unsplash.com/photo-1576046126313-e93a138bc984?ixlib=rb-1.2.1&auto=format&fit=crop&w=1990&q=80");


  background-size: auto;
  background-repeat: no-repeat;

  @include respond(phone) {
    background-size: cover;
    height: 70vh;
  }
}

.coverHeader {
  text-transform: uppercase;
  color: #fff;
  font-size: 7rem;
  padding-top: 25rem;

  @include respond(phone) {
    font-size: 4rem;
    padding-top: 0rem;
    transform: translateY(30rem);

  }

}


.coverSubHeader {
  text-transform: uppercase;
  background: -webkit-linear-gradient(#fff, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;

}

.heading-after {
  text-transform: uppercase;
  background: -webkit-linear-gradient(#fff, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 800;


}

.footer {
  // height: 20vh;
  background-color: #1a1a2e;
  padding: 5rem
}

.namerow {
  position: absolute;
  top: 0%;
  left: 10%;
  width: 100%;
}

.move-left {
  transform: translateX(-10%);
}

.logoImage {
  width: 20rem;
  height: 10rem;
  background-image: url("../logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 25% 0 0%;
  float: left;

  &-after {
    @include respond(phone) {
      width: 5rem;
      height: 5rem;
      // position: absolute;
      // // top: -100%;
      // left: 60%;
      box-sizing: border-box;
      background-image: url("../logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      // background-color: #0073a4;
    }
  }

  @include respond(too-big) {
    margin: 0% 25% 0 5%;
  }

  @include respond(mac) {
    margin: 0% 25% 0 5%;
  }

  @include respond(tab-pro) {
    margin: 0% 5% 0 5%;
  }

  @include respond(tab-port) {
    margin: 0% 2% 0 2%;
  }

  @include respond(phone) {
    width: 0;
  }
}

.navigationBAR {

  background-color: #1a1a2e;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;

  @include respond(phone) {
    width: 0;
    background-color: #16213e;
    position: relative;
  }

  &--menu {
    @include respond(too-big) {
      margin-top: 2.3rem;
      font-size: 1.5rem;
    }

    @include respond(mac) {
      margin-top: 2.3rem;
      font-size: 1.5rem;
    }

    @include respond(tab-land) {
      margin-top: 1.6rem;
      font-size: 1.5rem;
    }

    @include respond(tab-port) {
      margin-top: 1.6rem;
      font-size: 1.5rem;
    }
  }
}


.main_container {
  height: 100vh;
  background-color: #59253a;
  // background-image: linear-gradient(#F172a1,#59253a) ;
  background-image: url("../bodybuildermain.png");

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 100%;

  @include respond(phone) {
    // background-size: cover;
    background-position: center;
    height: 100%;
  }

}

.background--grey {
  background-color: rgba(0, 0, 0, 0.473);
  filter: grayscale(100%);
}

.menuItem {
  align-items: center;
  text-transform: uppercase;
  font-size: 1rem;
  color: hsla(0, 0%, 100%, .5);
  font-weight: 700;
  transition: .35s;
  cursor: pointer;

  &:hover {
    color: #fff;
  }

}

.blockname {
  box-sizing: border-box;
  width: 100%;
  display: block;

}

.blocknametext {
  font-size: 6.625rem;
  line-height: .88;
  font-weight: 700;
  font-style: italic;
  font-stretch: condensed;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;

  @include respond(phone) {
    font-size: 4rem;
  }
}

.genderBox {
  width: 20rem;
  height: 20rem;
  overflow: hidden;
  border: 2px solid #16ffbd;
  background-color: #16ffbd9a;
  transition: background .35s, border .35s;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .35s;
  // border-radius: 20rem;

  &:hover {
    transform: translateY(-2rem);
    -webkit-box-shadow: 5px 4px 44px 10px rgba(87, 162, 253, 0.29);
    -moz-box-shadow: 5px 4px 44px 10px rgba(87, 162, 253, 0.29);
    box-shadow: 5px 4px 44px 10px rgba(87, 162, 253, 0.29);
  }

  &:active {
    transform: translateY(0rem);

  }

}

.genderBoxWomen {
  width: 20rem;
  height: 20rem;
  overflow: hidden;
  border: 2px solid #16ffbd;
  background-color: #16ffbd9a;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background .35s, border .35s;
  cursor: pointer;
  transition: .35s;
  // border-radius: 20rem;

  &:hover {
    transform: translateY(-2rem);
    -webkit-box-shadow: 5px 4px 44px 10px rgba(190, 106, 242, 0.29);
    -moz-box-shadow: 5px 4px 44px 10px rgba(190, 106, 242, 0.29);
    box-shadow: 5px 4px 44px 10px rgba(190, 106, 242, 0.29);
  }

  &:active {
    transform: translateY(0rem);

  }

}

.genderOR {
  margin: 0 4rem;
  font-size: 1.25em;
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
  color: #fff;
}

.genderText {
  font-size: 1.8rem;
  font-weight: 700;
  font-style: italic;
  font-stretch: condensed;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  margin-top: 2rem;
  transition: color .35s;
}

.socialMediaBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
  width: 40rem;
  background: #A16383;
  // border-radius: 40rem;

  @include respond(phone) {
    height: 15rem;
    width: 100%;
    margin-left: 2%;
    justify-content: end;
    // max-width: 30%;
  }
}

.footer-mention {
  font-size: .813em;
  color: hsla(0, 0%, 100%, .5);
  text-transform: uppercase;
  // text-align: left;
}

.footer-heading-Text {
  font-size: 1.125em;
  font-weight: 700;
  font-style: italic;
  color: hsla(0, 0%, 100%, .5);
  text-transform: uppercase;
}

.footer-menuItem {
  font-size: .813em;
  color: hsla(0, 0%, 100%, .5);
  text-transform: uppercase;
  transition: color .35s;
  padding: 0 7px;

  &::hover {
    color: "#32f0e6"
  }
}

.fixed {

  position: fixed;

  &--top-left {
    top: 1rem;
    left: 0rem;

    @include respond(phone) {
      left: 0rem
    }

  }

  &--top-right {
    top: 3rem;
    right: 2rem
  }
}

.rowFixed {
  z-index: 99;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 10rem;
  pointer-events: none;
  background: linear-gradient(0deg, rgb(0, 0, 0) 60%, transparent);

}

.indexedStep {
  color: white;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}

.percentage__show {
  // color: #57a2fd;
  font-size: 3rem;
  font-weight: 700;
  font-style: italic;
  margin-left: 5%;
  background-image: linear-gradient(#32f0e6, #14a098);
  text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  // transition: all 3s;
  // animation: 
  //   pulse 3s ease infinite alternate;


}

.select_row {
  margin-top: 12rem;
}

.maleFemaleContainer {
  margin-top: 10rem;

  @include respond(phone) {
    margin-top: 5rem;
    // max-width: 30%;
  }
}

.socialbox--icon {
  position: absolute;
  top: 10%;
  right: 5%;

  @include respond(phone) {
    top: 10%;
    right: -10%;
  }

}

.faqSection {
  @include respond(phone) {
    margin-top: 5rem;
  }
}

.rowlabel {
  height: 6rem;
  max-width: 100%;
  min-width: auto;
  justify-content: center;
  padding: 0 30px;
  position: relative;
  display: flex;
  background: #65ccb8;
  align-items: center;
  text-align: center;
  border: none;
  line-height: .95;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  font-family: Roboto Condensed, sans-serif;
  color: #fff;
  text-transform: uppercase;
  transition: background .35s;
  margin-top: 2rem;
  font-size: 1.6rem;
  transition: .25s;
  z-index: 2;

  @include respond(phone) {
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
    background: #70e4cc;
    -webkit-box-shadow: 5px 4px 44px 10px rgba(87, 162, 253, 0.29);
    -moz-box-shadow: 5px 4px 44px 10px rgba(87, 162, 253, 0.29);
    box-shadow: 5px 4px 44px 10px rgba(87, 162, 253, 0.29);
    // transition: all 3s;
    // animation:
    //   pulse 8s ease infinite alternate;

  }

  &:active {
    transform: scale(1);

  }
}

.block {
  user-select: none;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  background: #070404;
  height: 100%;
  border: 2px solid #262525;
  margin: 1rem;
  padding: 1rem;
  max-width: 20rem;
}

.block__selected {
  user-select: none;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1f0d14;
  border-color: #59253a;
  height: 100%;
  border: 2px solid #262525;
  margin: 1rem;
  padding: 1rem;
  transition: all 3s;
  max-width: 20rem;


  // animation: 
  //   pulse 3s ease infinite alternate,
}

.block_header {
  font-size: 1.3rem;
  font-weight: 700;
  color: hsla(0, 0%, 100%, .5);
  text-transform: uppercase;
  transition: color .35s;
  margin-top: 2rem;
}

.heading {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;

  // margin-bottom: 30px;
  @include respond(phone) {
    font-size: 2rem;
  }
}

.sub_heading {
  font-size: 1rem;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

.shitBelow {
  margin-top: 5rem;
}

.icon-change {
  height: 5rem;

  @include respond(phone) {
    height: 2rem;
  }
}

.magic__inputs {
  padding: 0 15px;
  height: 60px;
  outline: none;
  background-color: #040813;
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  border: 2px solid #606469;
  border-top-color: rgb(96, 100, 105);
  border-top-style: solid;
  border-top-width: 2px;
  border-right-color: rgb(96, 100, 105);
  border-right-style: solid;
  border-right-width: 2px;
  border-bottom-color: rgb(96, 100, 105);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-left-color: rgb(96, 100, 105);
  border-left-style: solid;
  border-left-width: 2px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0 !important;
  width: 100%;
  font-family: Roboto Condensed;
  transition: background .35s, border .35s;
}

/* ---------- Custom loader ----------------- */

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}

/* ---------- Custom loader ----------------- */

.HeaderRow {
  z-index: 999;
  position: fixed;
  width: 100%;
  background-color: #59253a;
  min-height: 10rem;
  top: 0
}

.policies {
  margin-top: 10rem;
  background-color: #ffF;
  padding: 5rem;

  h1 {
    padding: 2rem;
  }

  h3 {
    padding: 2rem;
  }

  h5 {
    padding: 3rem;
  }
}