//MEDIA QUERY MANAGER
/*
0-600px - phone
600-900px - tablet
900-1200px- landscape tablet
1200-1800px - normal
1800+ px - big desktop
breakpoint content :
phone
tab-port
tab-land
big-desktop
1em = 16 px
*/
@mixin respond($breakpoint) {
    @if $breakpoint== minPhone {
        @media (max-width:22em) {
            @content
        };
        
    }
    @if $breakpoint == phone {
       @media (max-width:37.5em) {   //600px
           @content
       };
    }   
    @if $breakpoint == tab-port {
       @media (max-width:56.25em) {  //900px
           @content
       };
    }  
    @if $breakpoint == tab-pro { 
       @media (max-width:65em) { //1200px
           @content
       };
    }
    @if $breakpoint == tab-land { 
       @media (max-width:81em) { //1200px
           @content
       };
    }   
    @if $breakpoint == desktop {
       @media (max-width:90.6em) { //1450px
           @content
       };
    }
    @if $breakpoint == big-desktop {
       @media (max-width:112.5em) { //1800px
           @content
       };
    } 
    @if $breakpoint == hdpi {
       @media (max-width:121em) { //1800px
           @content
       };
    }  
    @if $breakpoint == small-mac {
       @media (max-width:137.5em) { 
           @content
       };
    }     
    @if $breakpoint == mac {
       @media (max-width:160em) { //2500x 137.5 187.5
           @content
       };
    }  
    @if $breakpoint == too-big {
       @media (min-width:161em) { //2500x 137.5 187.5   
           @content
       };
    }   
   }

   @keyframes pulse {
    0%, 100% {
      background-color: #57a2fd7c;
    }
    50% {
      background-color: #276bbf79;
    }
  }